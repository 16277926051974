import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import HomepageDrawInWrapper from 'components/homepage-draw-in-wrapper';

const HomepageDrawInWrapperContainer = ({ active }) => (
  <Consumer>
    {({ slideDrawing }) => (
      <HomepageDrawInWrapper slideDrawing={slideDrawing} active={active} />
    )}
  </Consumer>
);

HomepageDrawInWrapperContainer.propTypes = {
  active: PropTypes.number,
};

export default HomepageDrawInWrapperContainer;
