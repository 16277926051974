import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import HomepageSlideWrapper from 'components/homepage-slide-wrapper';

const HomepageSlideWrapperContainer = ({ children, order }) => (
  <Consumer>
    {({ setSlideDrawing }) => (
      <HomepageSlideWrapper order={order} setSlideDrawing={setSlideDrawing}>
        {children}
      </HomepageSlideWrapper>
    )}
  </Consumer>
);

HomepageSlideWrapperContainer.propTypes = {
  children: PropTypes.node.isRequired,
  order: PropTypes.number,
};

export default HomepageSlideWrapperContainer;
