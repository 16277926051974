import React from 'react';
import PropTypes from 'prop-types';

import IO from './io';
import { Container } from './io-workwithus.css';

class IOWorkwithus extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children } = this.props;

    return (
      <IO id="io-workwithus" key="io-workwithus" rootMargin="0% 0% -50% 0%">
        {({ hasBeenVisible }) => (
          <Container>{children({ hasBeenVisible })}</Container>
        )}
      </IO>
    );
  }
}

IOWorkwithus.propTypes = {
  showFooter: PropTypes.func,
  children: PropTypes.node,
};

export default IOWorkwithus;
