import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Container,
  Title,
  StyledLink,
  Flex,
  Description,
  Item,
  Color,
  Type,
  Date,
  Overlay,
  StyledImg,
} from './index-section-info.css';
import slugify from 'slugify';

const createUrl = (type, slug) => {
  if (type === 'journal') {
    return `/news-and-stories/${slug}`;
  }
  if (type === 'case-study') {
    return `/case-study/${slug}`;
  }
  if (type === 'volunteer') {
    return `/volunteer#${slug}`;
  }
};

const IndexSectionInfo = ({ title, description, items, color, all, type }) => {
  return (
    <Container className={`${items.length ? '' : 'pb-1'}`}>
      <Flex margin="mx-8 xxs:mx-10">
        <Title>{title && title}</Title>
        <StyledLink
          to={all}
          color={`border-brand-${color} hover:text-brand-${color}`}
        >
          View All
        </StyledLink>
      </Flex>
      <Description margin="mx-8 xxs:mx-10">
        {description && description}
      </Description>
      <Flex flex="flex-wrap mt-16 xs:mt-0">
        {items.map(
          (
            {
              node,
              title,
              text,
              projectIcon,
              location,
              slug,
              thumbnailImage,
              headerImage,
              link,
              caption,
            },
            i
          ) => (
            <Item
              key={i}
              to={
                link ||
                createUrl(
                  type,
                  slug ||
                    (node && node.slug) ||
                    (title && slugify(title, { lower: true })) ||
                    (node && node.title && slugify(node.title, { lower: true }))
                )
              }
              color={`border-brand-${color}`}
              width={
                items.length > 1 ? 'w-full lg:w-1/2 -my-px xs:-mx-px' : 'w-full'
              }
              mv={items.length == 3 && i == 1 && 'lg:homepage-bump'}
            >
              <Overlay color={`bg-brand-${color}`}>
                {((thumbnailImage && thumbnailImage.gatsbyImageData) ||
                  (headerImage && headerImage.gatsbyImageData) ||
                  (node &&
                    node.thumbnailImage &&
                    node.thumbnailImage.gatsbyImageData)) && (
                  <StyledImg
                    alt={thumbnailImage?.description}
                    image={
                      thumbnailImage?.gatsbyImageData ||
                      headerImage?.gatsbyImageData ||
                      (node && node?.thumbnailImage?.gatsbyImageData)
                    }
                    style={{ position: 'absolute' }}
                  />
                )}
              </Overlay>
              <div
                className={
                  color === 'pink'
                    ? 'typography large m-0 relative'
                    : 'typography m-0 relative'
                }
              >
                <span className="flex flex-wrap items-baseline">
                  {node && node.type && (
                    <Type color={`text-brand-${color}`}>
                      {Array.isArray(node.type) ? node.type.join(', ') : type}
                    </Type>
                  )}
                  {node && node.date && (
                    <Date>{moment(node.date).format('LL')}</Date>
                  )}
                </span>

                {projectIcon && (
                  <div className="h-40 relative">
                    <GatsbyImage
                      loading="eager"
                      className="h-auto w-40 filter-invert transition duration-200 ease-in-out opacity-0 group-hover:opacity-100"
                      style={{ position: 'absolute' }}
                      image={projectIcon?.gatsbyImageData}
                      alt={projectIcon?.description}
                    />
                    <GatsbyImage
                      loading="eager"
                      className="h-auto w-40 -z-1"
                      style={{ position: 'absolute' }}
                      image={projectIcon?.gatsbyImageData}
                      alt={projectIcon?.description}
                    />
                    <Color bg={`h-40 bg-brand-${color}`} />
                  </div>
                )}
                {(title || node?.title || text || node?.text) && (
                  <h3
                    style={{ marginBottom: 0 }}
                    className={
                      color === 'pink'
                        ? 'transition duration-200 ease-in-out text-brand-pink group-hover:text-white'
                        : ' group-hover:text-white transition duration-200 ease-in-out'
                    }
                  >
                    {title || node?.title || text || node?.text}
                  </h3>
                )}
                {(location || (node && node.location)) && (
                  <h4 className="text-brand-orange mt-4 group-hover:text-white transition duration-200 ease-in-out">
                    {location || node.location}
                  </h4>
                )}
                {(caption || node?.caption) && (
                  <h4 className="text-brand-green mt-4 group-hover:text-white transition duration-200 ease-in-out">
                    {caption || node?.caption}
                  </h4>
                )}
              </div>
            </Item>
          )
        )}
      </Flex>
    </Container>
  );
};

IndexSectionInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.array,
  color: PropTypes.string,
  all: PropTypes.string,
  type: PropTypes.string,
};

export default IndexSectionInfo;
