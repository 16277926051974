import React from 'react';
import PropTypes from 'prop-types';

import Sticky from 'react-stickynode';

const HomepageSlideWrapper = ({ setSlideDrawing, children, order }) => (
  // <Sticky
  //   topOffset={-1}
  //   bottomOffset={1000}
  //   boundaryElement=".slide-boundary"
  //   hideOnBoundaryHit={false}
  //   className="disable-transform relative large-slide"
  //   stickyStyle={{ display: 'block' }}
  //   style={{ zIndex: order, display: 'none' }}
  //   onFixedToggle={isFixed => {
  //     setSlideDrawing(isFixed ? order - 1 : order);
  //   }}
  // >
  //   {children}
  // </Sticky>
  <Sticky
    top={-1}
    bottomBoundary={'.bottom-boundary'}
    className={'disable-transform relative large-slide -mt-px'}
    enableTransforms={false}
    innerZ={order}
    onStateChange={status => {
      if (status.status === Sticky.STATUS_RELEASED) {
        setSlideDrawing(null);
        return;
      }
      status.status !== Sticky.STATUS_FIXED
        ? order !== 0 && setSlideDrawing(order - 1)
        : setSlideDrawing(order);
    }}
  >
    {children}
  </Sticky>
);

HomepageSlideWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  setSlideDrawing: PropTypes.func,
  order: PropTypes.number,
};

export default HomepageSlideWrapper;
